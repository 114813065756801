import { DestinationCarouselComponent } from '@virgin-atlantic/component-lib';
import Section from 'src/components/layout-components/home-page-section.component';
import SpacerComponent from 'src/components/layout-components/spacer.component';
import HeadingComponent from 'src/components/heading-component/heading-component.component';
import ContentCardContainer from 'src/components/content-card-container/content-card-container.component';
import CountdownTimerComponent from 'src/components/countdown-timer/countdown-timer.component';
import TextLockupComponent from '../text-lockup/text-lockup.component';
import CarouselComponent from '../carousel/carousel.component';
import TailfinHeroBannerComponent from '../tailfin-hero-banner/tailfin-hero-banner.component';
import IconPanelComponent from '../icon-panel/icon-panel.component';
import ContentCardComponent from '../content-card/content-card.component';
import { PromoBannerComponent } from '@vaa-component-lib/component.molecule.promo-banner';
import { GlobalAlertBannerComponent } from '@vaa-component-lib/component.molecule.global-alert-banner';
import { GridComponent } from '@vaa-component-lib/component.layout.grid';
import GridItem from '../grid-item/grid-item.component';
import { TailfinHeroBannerTwoComponent } from '../tailfin-hero-banner-two/tailfin-hero-banner-two.component';
import HotelsCarouselComponent from '../hotels-carousel/hotels-carousel.component';
import HotelsMapComponent from '../hotels-map/hotels-map.component';
import SearchPanelComponent from '../search-panel/search-panel.component';
import AccordionComponent from '../accordion/accordion-component';
import TabsComponent from '../tabs/tabs-component';
import ArticleListContainer from '../article-list-container/article-list-container.component';
import RouteMapComponent from '../route-map/route-map.component';
import LinksBanner from '../link-banner/link-banner.component';
import Breadcrumbs from '../breadcrumbs/breadcrumbs.component';
import ClimateChartComponent from '../climate-chart/climate-chart.component';
import TableCard from '../table-card/table-card.component';
import HeroBanner from '../hero-banner/hero-banner.component';
import VideoComponent from '../video-component/video-component.component';

// Renders AEM container object contents (items)
export default function PageRenderer({ items }: { items: any }) {
  if (!items || items.length === 0) return null;

  return (
    <GridComponent as="section">
      {items[0].components.map((item: any, index: any) => {
        const { C, fullWidth } = getComponent(item.componentType);
        const HOMEPAGE_SEARCH_PANEL = 'homepage-search-panel';
        const isHomepageSearchPanel = item.componentProps && item.componentProps.componentId === HOMEPAGE_SEARCH_PANEL;
        
        if (!C) return null;
        return (
          <GridItem
            key={`grid-item--${index}`}
            span={12}
            offset={0}
            isFullWidth={fullWidth}
            marginBottom={false}
            {...isHomepageSearchPanel && { gridRow: { mobile: 1, desktop: 'auto' } }}
          >
            <C key={`item--${index}`} {...item.componentProps} />
          </GridItem>
        );
      })}
    </GridComponent>
  );
}

export const getComponent = (
  componentType: string
): { C: any; fullWidth: boolean } => {
  switch (componentType) {
    case 'container':
      return { C: Section, fullWidth: true };
    case 'destination-carousel':
      return { C: DestinationCarouselComponent, fullWidth: true };
    case 'content-card':
      return { C: ContentCardComponent, fullWidth: false };
    case 'content-card-container':
      return { C: ContentCardContainer, fullWidth: false };
    case 'global-alert-banner':
      return { C: GlobalAlertBannerComponent, fullWidth: true };
    case 'icon-panel':
      return { C: IconPanelComponent, fullWidth: true };
    case 'herobanner':
      return { C: HeroBanner, fullWidth: true };
    case 'promobanner':
      return { C: PromoBannerComponent, fullWidth: true };
    case 'tailfin-hero-banner':
      return { C: TailfinHeroBannerComponent, fullWidth: true };
    case 'tailfin-hero-banner-two':
      return { C: TailfinHeroBannerTwoComponent, fullWidth: true };
    case 'text-lockup-component':
      return { C: TextLockupComponent, fullWidth: false };
    case 'carousel':
      return { C: CarouselComponent, fullWidth: true };
    case 'countdown-timer':
      return { C: CountdownTimerComponent, fullWidth: true };
    case 'heading-component':
      return { C: HeadingComponent, fullWidth: true };
    case 'hotels-carousel':
      return { C: HotelsCarouselComponent, fullWidth: true };
    case 'hotels-map':
      return { C: HotelsMapComponent, fullWidth: false };
    case 'spacer-component':
      return { C: SpacerComponent, fullWidth: true };
    case 'search-panel':
      return { C: SearchPanelComponent, fullWidth: true };
    case 'accordion':
      return { C: AccordionComponent, fullWidth: false };
    case 'tabs':
      return { C: TabsComponent, fullWidth: false };
    case 'articleslist':
      return { C: ArticleListContainer, fullWidth: true };
    case 'route-map':
      return { C: RouteMapComponent, fullWidth: false };
    case 'link-banner':
      return { C: LinksBanner, fullWidth: false };
    case 'breadcrumbs':
      return { C: Breadcrumbs, fullWidth: false };
    case 'climate-chart':
      return { C: ClimateChartComponent, fullWidth: false };
    case 'table-card':
      return { C: TableCard, fullWidth: false };
    case 'video-component':
      return { C: VideoComponent, fullWidth: false };
    default:
      return { C: null, fullWidth: false };
  }
};
