
import React from 'react';
import { FocalPoint, HeroBannerComponent } from '@vaa-component-lib/component.molecule.hero-banner';
import styles from './hero-banner.component.module.less';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { subscribe, unsubscribe, SEARCH_PANEL_EVENT } from 'src/utils/events';

type HeroBannerContent = {
    title: string;
    text: string;
    buttons: {
        text: string;
        href: string;
    }[];
    focalPoint?: FocalPoint;
    desktopImage: {
        alt: string;
        url: string;
    }
    mobileImage: {
        alt: string;
        url: string;
    }
}

enum VisibleContentEnum {
    PRIMARY = 'primary',
    SECONDARY = 'secondary'
}

interface HeroBannerProps {
    componentId?: string;
    title: string;
    text: string;
    buttons: {
        text: string;
        href: string;
    }[];
    focalPoint?: FocalPoint;
    desktopImage: {
        alt: string;
        url: string;
    }
    mobileImage: {
        alt: string;
        url: string;
    }
    secondaryContent?: HeroBannerContent;
}

const HeroBanner = ({
    componentId,
    title,
    text,
    buttons,
    focalPoint,
    desktopImage,
    mobileImage,
    secondaryContent
}: HeroBannerProps) => {

    const [visibleContent, setVisibleContent] = useState<VisibleContentEnum>(VisibleContentEnum.PRIMARY);
    const [height, setHeight] = useState<number>(0)
    const heroBannerContainerRef = useRef<HTMLDivElement>(null)
    const primaryBannerRef = useRef<HTMLInputElement>(null)
    const secondaryBannerRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (primaryBannerRef.current || secondaryBannerRef.current) {
            const primary = primaryBannerRef.current;
            const secondary = secondaryBannerRef.current;
            const primaryBannerSection = primary && primary.querySelector('section')
            const secondaryBannerSection = secondary && secondary.querySelector('section')
            const heights = [primaryBannerSection?.clientHeight, secondaryBannerSection?.clientHeight].filter((height) => height !== null && height !== undefined)
            const maxHeight = Math.max(...heights)

            setHeight(maxHeight)

        }
    }, [primaryBannerRef, secondaryBannerRef])

    const handleContentChange = (content: VisibleContentEnum) => {
        if (!secondaryContent || !secondaryContent.desktopImage.url || !secondaryContent.mobileImage.url) {
            console.error('Hero Banner secondary content is missing required fields: ', secondaryContent);
            return;
        }
        setVisibleContent(content);
    }

    useEffect(() => {
        subscribe(SEARCH_PANEL_EVENT, (e: any) => {
            const bookingType = e.detail['bookingType'];
            const content = (
                bookingType === 'HOLIDAY' ||
                bookingType === 'MULTICENTRE' ||
                bookingType === 'FLYDRIVE'
            ) ? VisibleContentEnum.SECONDARY : VisibleContentEnum.PRIMARY;

            handleContentChange(content);
        });
        return () => unsubscribe(SEARCH_PANEL_EVENT, () => {
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visibleContent]);

    const primaryProps = {
        componentId,
        title,
        text,
        focalPoint,
        buttons,
        desktopImage,
        mobileImage
    }

    const secondaryProps = secondaryContent ? {
        componentId,
        title: secondaryContent.title || '',
        text: secondaryContent.text || '',
        focalPoint: secondaryContent.focalPoint || FocalPoint.Center,
        buttons: secondaryContent.buttons || [],
        desktopImage: secondaryContent.desktopImage || { alt: '', url: '' },
        mobileImage: secondaryContent.mobileImage || { alt: '', url: '' }
    } : null

    return (
        <div className={classNames(styles['hero-banner'])}
            ref={heroBannerContainerRef}
        >
            <div
                id='primary-banner'
                className={classNames(styles['hero-banner-item'], {
                    [styles['fadeout']]: visibleContent === VisibleContentEnum.SECONDARY,
                    [styles['fadein']]: visibleContent === VisibleContentEnum.PRIMARY,
                    [styles['active']]: visibleContent === VisibleContentEnum.PRIMARY,
                })}
                ref={primaryBannerRef}
                style={{ minHeight: `${height}px` }}
            >
                <HeroBannerComponent {...primaryProps} />
            </div>
            {secondaryProps && (
                <div
                    id='secondary-banner'
                    className={classNames(styles['hero-banner-item'], {
                        [styles['fadeout']]: visibleContent === VisibleContentEnum.PRIMARY,
                        [styles['fadein']]: visibleContent === VisibleContentEnum.SECONDARY,
                        [styles['active']]: visibleContent === VisibleContentEnum.SECONDARY,
                    })}
                    ref={secondaryBannerRef}
                    style={{ minHeight: `${height}px` }}
                >
                    <HeroBannerComponent {...secondaryProps} />
                </div>
            )}
        </div>
    );
}

export default HeroBanner;