import { ReactNode } from 'react';
import styles from './grid-item.component.module.less';
import classnames from 'classnames';

interface GridItemComponentProps {
  children: ReactNode | ReactNode[];
  span: number;
  offset?: number;
  isFullWidth?: boolean;
  marginBottom?: boolean;
  gridRow?: {
    mobile: number | 'auto',
    desktop: number | 'auto',
  };
}

const GridItem = ({ children, span, offset = 0, isFullWidth, marginBottom, gridRow }: GridItemComponentProps) => {
  if (!children) {
    return null;
  }

  return (
    <div className={classnames(styles.gridItem, {
      [styles[`span-${span}`]]: true,
      [styles[`offset-${offset}`]]: true,
      [styles['full-width']]: !isFullWidth,
      [styles['margin-bottom']]: marginBottom,
      [styles[`grid-row-mob-${gridRow?.mobile}`]]: gridRow?.mobile,
      [styles[`grid-row-desk-${gridRow?.desktop}`]]: gridRow?.desktop,
    })}
    >{children}</div>
  );
};

export default GridItem;
